import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, useHistory} from '@computerrock/formation-router';
import {europeanCountries, alfClientTypes, alfAddressTypes, /* alfBusinessRelationTypes, */ persistenceStates} from '@ace-de/eua-entity-types';
import {NoResultsBlock, ToggleTab, ToggleTabSwitch, useStyles, Paginator, LoaderSpinner, HighlightCircle} from '@ace-de/ui-components';
import {Button, ButtonIcon, ButtonPrimary} from '@ace-de/ui-components/buttons';
import {Form, InputField, SelectField, Option} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {infoAlertIcon, Icon, closeIcon, plusIcon, searchIcon, checkmarkIcon, editIcon} from '@ace-de/ui-components/icons';
import modalIds from '../../modalIds';
import config from '../../config';
import * as contractPartnersActionTypes from '../contractPartnerActionTypes';
import bcModalTypes from '../modals/bcModalTypes';

// todo uncomment businessRelations filter related code, once that filtering is implemented on NAV

const initialContactSearchParams = {
    client: alfClientTypes.ACE,
    ids: '',
    name: '',
    street: '',
    postCode: '',
    city: '',
    addressTypes: [],
    /* businessRelations: [alfBusinessRelationTypes.CONTRACT_PARTNER], */
};

const BCContactSearchModalView = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('bc_contact_search_modal');
    const {searchContacts, initiateCreditorCreation, initiateContractPartnerCreation} = props;
    const {initiateContractPartnerBCContactUpdate, initiateContractPartnerBCCreditorUpdate} = props;
    const {contactSearchResults, contactSearchResultsCount, isContactSearchError, isContactSearchPerformed} = props;
    const {contactsSearchPersistenceState} = props;
    const searchParams = new URLSearchParams(history.location.search);
    const [formData, setFormData] = useState({
        client: searchParams.get('client') || initialContactSearchParams.client,
        ids: searchParams.get('ids') || initialContactSearchParams.ids,
        name: searchParams.get('name') || initialContactSearchParams.name,
        street: searchParams.get('street') || initialContactSearchParams.street,
        postCode: searchParams.get('postCode') || initialContactSearchParams.postCode,
        city: searchParams.get('city') || initialContactSearchParams.city,
        addressTypes: searchParams.getAll('addressTypes').length > 0
            ? searchParams.getAll('addressTypes')
            : initialContactSearchParams.addressTypes,
        /* businessRelations: searchParams.getAll('businessRelations').length > 0
            ? searchParams.getAll('businessRelations')
            : initialContactSearchParams.businessRelations, */
    });
    const [savedFormDataForSearchResults, setSavedFormDataForSearchResults] = useState(formData);
    const [selectedContact, setSelectedContact] = useState(null);
    const [queryParams, setQueryParams] = useState(searchParams);

    const paginatorCount = Math.ceil(contactSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const isSearchInProgress = contactsSearchPersistenceState === persistenceStates.PENDING;

    const formatQueryParams = formData => {
        if (!formData) return;
        const searchQueryParams = new URLSearchParams();

        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '') {
                if (formField !== 'addressTypes' /* && formField !== 'businessRelations' */) {
                    searchQueryParams.append(`${formField}`, formData[formField]);
                }

                if ((formField === 'addressTypes' /* || formField === 'businessRelations' */)
                    && Array.isArray(formData[formField]) && formData[formField].length > 0) {
                    formData[formField].forEach(category => {
                        if (category !== '') searchQueryParams.append(formField, category);
                    });
                }
            }
        });

        return searchQueryParams;
    };

    const handleOnChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleOnToggleSwitch = value => {
        handleOnChange('client', value);
        let isFormEmpty = true;
        Object.keys(savedFormDataForSearchResults).forEach(formField => {
            if (formField !== 'client' && !!savedFormDataForSearchResults[formField]) isFormEmpty = false;
        });
        if (isFormEmpty) return;

        const searchQueryParams = formatQueryParams({
            ...savedFormDataForSearchResults,
            client: value,
            modal: modalIds.BC_CONTACT_MODAL,
        });

        searchContacts({searchQueryParams});

        setSelectedContact(null);
    };

    const handleOnSubmit = () => {
        const searchQueryParams = formatQueryParams({
            ...formData,
            modal: modalIds.BC_CONTACT_MODAL,
        });

        if (searchQueryParams.size === 2 && searchQueryParams.has('modal') && searchQueryParams.has('client')) return;

        searchContacts({searchQueryParams});
        setSelectedContact(null);
        setSavedFormDataForSearchResults(formData);
    };

    const handlePaginationPage = page => {
        const searchQueryParams = formatQueryParams({
            ...formData,
            modal: modalIds.BC_CONTACT_MODAL,
        });
        searchQueryParams.set('page', `${page}`);
        searchQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        searchContacts({searchQueryParams});
        setSelectedContact(null);
        setQueryParams(searchQueryParams);
    };

    return (
        <div>
            <div
                className={cx([
                    'global!ace-u-padding--32-32-0',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <ToggleTabSwitch
                    name="clientBCSearch"
                    className={cx('global!ace-u-margin--bottom-24')}
                    onChange={value => handleOnToggleSwitch(value)}
                    value={formData.client}
                    isDisabled={isSearchInProgress}
                >
                    {Object.values(alfClientTypes).map(tab => (
                        <ToggleTab
                            key={tab}
                            name={`${tab.toLowerCase()}Tab`}
                            value={tab}
                            isDisabled={isSearchInProgress}
                        >
                            {tab}
                        </ToggleTab>
                    ))}
                </ToggleTabSwitch>
                <Button
                    onClick={() => initiateContractPartnerCreation({
                        modalType: bcModalTypes.CREATE_CONTRACT_PARTNER,
                        client: formData.client,
                    })}
                    isDisabled={isSearchInProgress}
                >
                    <Icon
                        className={cx([
                            'global!ace-c-icon--color-highlight',
                            'global!ace-c-icon--s',
                            'global!ace-u-margin--right-8',
                        ])}
                        icon={plusIcon}
                    />
                    {translateModal('button.create_contact')}
                </Button>
            </div>
            <div className={cx(['global!ace-u-padding--0-32', 'global!ace-u-width--full'])}>
                <Form name="bcContactSearch" onSubmit={handleOnSubmit}>
                    <p
                        className={cx([
                            'global!ace-u-margin--24-0',
                            'global!ace-u-typography--variant-body-bold',
                        ])}
                    >
                        {translateModal('form_title.contact_data')}
                    </p>
                    <div
                        className={cx([
                            'global!ace-u-margin--bottom-40',
                            'global!ace-u-width--full',
                            'global!ace-u-grid',
                            'global!ace-u-flex--align-flex-end',
                            'global!ace-u-flex--justify-space-between',
                        ])}
                        onKeyDown={event => {
                            if (event.key === 'Enter') handleOnSubmit();
                        }}
                    >
                        {Object
                            .keys(formData)
                            .filter(formField => !['addressTypes'/* , 'businessRelations' */].includes(formField))
                            .map(formField => {
                                if (formField === 'client') {
                                    return null;
                                }
                                return (
                                    <div
                                        key={formField}
                                        className={cx({
                                            'global!ace-u-grid-column--span-1': ['ids', 'postCode'].includes(formField),
                                            'global!ace-u-grid-column--span-2': !['ids', 'postCode'].includes(formField),
                                        })}
                                    >
                                        <InputField
                                            name={formField}
                                            label={translateModal(`input_label.${formField}`)}
                                            value={formData[formField]}
                                            className={cx([
                                                'global!ace-u-width--full',
                                            ])}
                                            onChange={value => handleOnChange(formField, value)}
                                            isDisabled={isSearchInProgress}
                                        />
                                    </div>
                                );
                            })}
                        <div
                            key="addressTypes"
                            className={cx(['global!ace-u-grid-column--span-3'])}
                        >
                            <SelectField
                                name="addressTypes"
                                label={translateModal('input_label.address_type')}
                                value={formData.addressTypes}
                                isMultipleChoice={true}
                                className={cx('global!ace-u-width--full')}
                                onChange={value => handleOnChange('addressTypes', value)}
                                isDisabled={isSearchInProgress}
                            >
                                {Object.keys(alfAddressTypes).map(alfAddressType => (
                                    <Option
                                        name={`${alfAddressType.toLowerCase()}Option`}
                                        key={alfAddressType}
                                        value={alfAddressType}
                                    >
                                        {translate(`global.contact_address_type.${alfAddressType.toLowerCase()}`)}
                                    </Option>
                                ))}
                            </SelectField>
                        </div>
                        {/* <div
                            key="businessRelations"
                            className={cx(['global!ace-u-grid-column--span-2'])}
                        >
                            <SelectField
                                name="types"
                                label={translateModal('input_label.business_relations')}
                                value={formData.businessRelations}
                                isMultipleChoice={true}
                                className={cx('global!ace-u-width--full')}
                                onChange={value => handleOnChange('businessRelations', value)}
                            >
                                {Object.values(alfBusinessRelationTypes)
                                    // eslint-disable-next-line max-len
                                    .filter(businessRelationType => (
                                    businessRelationType !== alfBusinessRelationTypes.MEMBER
                                    )).map(businessRelationType => (
                                        <Option
                                            name={`${businessRelationType.toLowerCase()}Option`}
                                            key={businessRelationType}
                                            value={businessRelationType}
                                        >
                                            {translateModal(`select_option.${businessRelationType.toLowerCase()}`)}
                                        </Option>
                                    ))}
                            </SelectField>
                        </div> */}
                        <ButtonIcon
                            name="searchButton"
                            icon={searchIcon}
                            type="submit"
                            className={cx([
                                'global!ace-u-flex--basis-5',
                                'global!ace-u-width--64',
                                'global!ace-u-height--48',
                            ])}
                            isDisabled={isSearchInProgress}
                        />
                    </div>
                </Form>
            </div>
            <div
                className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center', 'global!ace-u-flex--direction-column')}
            >
                <Table qaIdent="bc-contacts-search-results" className={cx('global!ace-u-padding--24')}>
                    {isContactSearchPerformed
                        && (isContactSearchError || contactSearchResults.length === 0)
                        && !isSearchInProgress
                        && (
                            <TableCaption>
                                <NoResultsBlock
                                    colorVariant={!isContactSearchError ? 'primary-highlight' : 'negative'}
                                    icon={(
                                        <Icon
                                            className={cx('ace-c-icon--xxl', {
                                                'global!ace-c-icon--color-highlight': !isContactSearchError,
                                                'global!ace-c-icon--color-warning': isContactSearchError,
                                            })}
                                            icon={infoAlertIcon}
                                        />
                                    )}
                                    description={!isContactSearchError
                                        ? translateModal('no_results.description')
                                        : translateModal('results_error.description')
                                    }
                                    message={!isContactSearchError
                                        ? translateModal('no_results.message')
                                        : translateModal('results_error.message')
                                    }
                                />
                            </TableCaption>
                        )}
                    {isSearchInProgress && (
                        <TableCaption>
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--medium',
                                    'ace-c-highlight-circle--primary-highlight',
                                    'global!ace-u-margin--top-64',
                                ])}
                            >
                                <LoaderSpinner />
                            </HighlightCircle>
                            <p
                                className={cx([
                                    'global!ace-u-margin--top-16',
                                    'global!ace-u-margin--bottom-64',
                                    'global!ace-u-typography--variant-h4',
                                ])}
                            >
                                {translateModal('loader_message.search_in_progress')}
                            </p>
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} qaIdentPart="bc-contact-id">
                                {translateModal('table_data_row.contact_id')}
                            </TableCell>
                            <TableCell colSpan={1} qaIdentPart="bc-contact-type">
                                {translateModal('table_data_row.type')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="bc-contact-category">
                                {translateModal('table_data_row.category')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="bc-contact-name">
                                {translateModal('table_data_row.name')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="bc-contact-address-street">
                                {translateModal('table_data_row.address')}
                            </TableCell>
                            <TableCell colSpan={1} qaIdentPart="bc-contact-address-post-code">
                                {translateModal('table_data_row.postCode')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="bc-contact-address-city">
                                {translateModal('table_data_row.city')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="bc-contact-address-country">
                                {translateModal('table_data_row.country')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="bc-contact-creditor-id">
                                {translateModal('table_data_row.creditor')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="bc-contact-debtor-id">
                                {translateModal('table_data_row.debtor')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isContactSearchError && !isSearchInProgress && contactSearchResults.map(contact => (
                            <TableRow
                                key={contact.id}
                                onClick={() => setSelectedContact(contact)}
                                className={cx({
                                    'global!ace-u-typography--variant-body-bold': selectedContact?.id === contact.id,
                                })}
                                qaIdentPart={contact.id}
                            >
                                <TableCell colSpan={2} qaIdentPart="bc-contact-id" qaIdentPartPostfix={contact.id}>
                                    {contact.id || '-'}
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="bc-contact-type" qaIdentPartPostfix={contact.id}>
                                    {translateModal(`contact_type.${contact.type.toLowerCase()}`)}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="bc-contact-category" qaIdentPartPostfix={contact.id}>
                                    {contact.businessRelations.length > 0
                                        ? contact.businessRelations.map((businessRelation, idx) => (
                                            `${translate(`global.business_relation_type.${businessRelation.toLowerCase()}`)}${idx < (contact.businessRelations.length - 1) ? ', ' : ''}`
                                        ))
                                        : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="bc-contact-name" qaIdentPartPostfix={contact.id}>
                                    {contact.name || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="bc-contact-address-street" qaIdentPartPostfix={contact.id}>
                                    {contact.address?.street || '-'}
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="bc-contact-address-post-code" qaIdentPartPostfix={contact.id}>
                                    {contact.address?.postCode || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="bc-contact-address-city" qaIdentPartPostfix={contact.id}>
                                    {contact.address?.city || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="bc-contact-address-country" qaIdentPartPostfix={contact.id}>
                                    {contact.address?.country
                                        ? Object.values(europeanCountries).find(country => {
                                            return country?.name === contact.address.country;
                                        }) ? translate(
                                                `global.country.${snakeCase(contact.address.country)}`,
                                            ) : translate('global.country.other')
                                        : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="bc-contact-creditor-id" qaIdentPartPostfix={contact.id}>
                                    {contact.creditorId
                                        ? (
                                            <Icon
                                                className={cx('global!ace-c-icon--color-success')}
                                                icon={checkmarkIcon}
                                            />
                                        )
                                        : <Icon icon={closeIcon} />}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="bc-contact-debtor-id" qaIdentPartPostfix={contact.id}>
                                    {contact.debtorId
                                        ? (
                                            <Icon
                                                className={cx('global!ace-c-icon--color-success')}
                                                icon={checkmarkIcon}
                                            />
                                        )
                                        : <Icon icon={closeIcon} />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {contactSearchResultsCount > 0 && !isSearchInProgress && (
                    <Paginator
                        page={+queryParams.get('page')}
                        count={paginatorCount}
                        onClick={page => handlePaginationPage(page)}
                    />
                )}
            </div>
            {(!isContactSearchPerformed || contactSearchResultsCount > 0) && (
                <div
                    className={cx([
                        'global!ace-u-margin--32',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    <Button
                        isDisabled={!selectedContact}
                        onClick={() => {
                            selectedContact?.creditorId
                                ? initiateContractPartnerBCCreditorUpdate({
                                    selectedContact,
                                    modalType: bcModalTypes.UPDATE_CREDITOR,
                                    client: formData.client,
                                })
                                : initiateContractPartnerBCContactUpdate({
                                    selectedContact,
                                    modalType: bcModalTypes.UPDATE_CONTRACT_PARTNER,
                                    client: formData.client,
                                });
                        }}
                    >
                        <Icon
                            icon={editIcon}
                            className={cx('global!ace-u-margin--right-8', [
                                ...(!selectedContact ? ['global!ace-c-icon--color-disabled'] : ['global!ace-c-icon--color-highlight']),
                            ])}
                        />
                        {selectedContact && selectedContact.creditorId
                            ? translateModal('button_label.edit_creditor')
                            : translateModal('button_label.edit_contact')}
                    </Button>
                    <ButtonPrimary
                        isDisabled={!selectedContact || !!(selectedContact && selectedContact.creditorId)}
                        className={cx('global!ace-u-margin--left-24')}
                        onClick={() => initiateCreditorCreation({
                            selectedContact,
                            modalType: bcModalTypes.CREATE_CREDITOR,
                            client: formData.client,
                        })}
                    >
                        <Icon
                            icon={plusIcon}
                            className={cx('global!ace-c-icon--color-contrast', 'global!ace-u-margin--right-8')}
                        />
                        {translateModal('button_label.add_creditor')}
                    </ButtonPrimary>
                </div>
            )}
        </div>
    );
};

BCContactSearchModalView.propTypes = {
    searchContacts: PropTypes.func.isRequired,
    contactSearchResults: PropTypes.array,
    contactSearchResultsCount: PropTypes.number,
    isContactSearchError: PropTypes.bool,
    isContactSearchPerformed: PropTypes.bool,
    initiateContractPartnerCreation: PropTypes.func.isRequired,
    initiateCreditorCreation: PropTypes.func.isRequired,
    initiateContractPartnerBCContactUpdate: PropTypes.func.isRequired,
    initiateContractPartnerBCCreditorUpdate: PropTypes.func.isRequired,
    contactsSearchPersistenceState: PropTypes.string.isRequired,
};

BCContactSearchModalView.defaultProps = {
    contactSearchResults: [],
    contactSearchResultsCount: 0,
    isContactSearchError: false,
    isContactSearchPerformed: false,
};

const mapStateToProps = state => {
    return {
        contactSearchResults: state.contractPartners.contactSearchResults,
        contactSearchResultsCount: state.contractPartners.contactSearchResultsCount,
        isContactSearchError: state.contractPartners.isContactSearchError,
        isContactSearchPerformed: state.contractPartners.isContactSearchPerformed,
        contactsSearchPersistenceState: state.contractPartners.contactsSearchPersistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    searchContacts: payload => dispatch({
        type: contractPartnersActionTypes.SEARCH_BC_CONTACTS,
        payload,
    }),
    initiateContractPartnerCreation: payload => dispatch({
        type: contractPartnersActionTypes.INITIATE_CONTRACT_PARTNER_CREATION_MODAL_FLOW,
        payload,
    }),
    initiateCreditorCreation: payload => dispatch({
        type: contractPartnersActionTypes.INITIATE_CREDITOR_CREATION_MODAL_FLOW,
        payload,
    }),
    initiateContractPartnerBCContactUpdate: payload => dispatch({
        type: contractPartnersActionTypes.INITIATE_BC_CONTACT_UPDATE_MODAL_FLOW,
        payload,
    }),
    initiateContractPartnerBCCreditorUpdate: payload => dispatch({
        type: contractPartnersActionTypes.INITIATE_BC_CREDITOR_UPDATE_MODAL_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BCContactSearchModalView));
